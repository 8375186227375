@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "maragsa";
  src: url("../public/fonts/maragsa/maragsa.otf");
}

@font-face {
  font-family: "garet bold";
  src: url("../public/fonts/garet/garet-heavy.ttf");
}

@font-face {
  font-family: "garet regular";
  src: url("../public/fonts/garet/garet-reg.ttf");
}

html {
  scroll-behavior: smooth;
}

h1, h2 {
  @apply font-maragsa;
}

h3, h4, h5 {
  @apply font-garet;
}

body {
  @apply font-garet text-base font-semibold text-left;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  line-height: 1.75rem;
}

a {
  @apply text-darkBlue no-underline;
}

a:hover {
  @apply text-lightBlue no-underline;
}

footer {
  @apply bg-rust;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen(md) {
  h1 {
    @apply text-7xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl;
  }
  h4 {
    @apply text-2xl;
  }
  body {
    @apply text-lg;
  }
}